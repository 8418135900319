@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'QuincyCF-Bold';
    font-weight: 700;
    src: url('/fonts/Quincy-CF/quincy-cf-bold.woff') format('woff'),
        url('/fonts/Quincy-CF/quincy-cf-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'OakesGrotesk';
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    src: url('/fonts/Oakes-Grotesk/oakes-grotesk-regular.woff') format('woff'),
        url('/fonts/Oakes-Grotesk/oakes-grotesk-regular.woff2') format('woff2');
}

@font-face {
    font-family: 'OakesGrotesk';
    font-weight: 400;
    font-style: italic;
    line-height: normal;
    src: url('/fonts/Oakes-Grotesk/oakes-grotesk-italic.woff') format('woff'),
        url('/fonts/Oakes-Grotesk/oakes-grotesk-italic.woff2') format('woff2');
}

@font-face {
    font-family: 'OakesGrotesk';
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    src: url('/fonts/Oakes-Grotesk/oakes-grotesk-medium.woff') format('woff'),
        url('/fonts/Oakes-Grotesk/oakes-grotesk-medium.woff2') format('woff2');
}

@font-face {
    font-family: 'OakesGrotesk';
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    src: url('/fonts/Oakes-Grotesk/oakes-grotesk-semi-bold.woff') format('woff'),
        url('/fonts/Oakes-Grotesk/oakes-grotesk-semi-bold.woff2')
            format('woff2');
}

:root {
    --reach-dialog: 1;
    --reach-tabs: 1;
}

[data-reach-dialog-overlay] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}

[data-reach-tab-list] {
    @apply flex;
    @apply overflow-x-scroll;
}

[data-reach-tab-list] > button {
    @apply inline-block;
    @apply min-w-max;
}

a {
    @apply text-primary-600;
    @apply font-semibold;
}

a:hover {
    @apply underline;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

[type='date']::-webkit-inner-spin-button {
    display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
    display: none;
}

@layer utilities {
    @variants responsive {
        .h-2px {
            height: 2px;
        }
        .h-14px {
            height: 14px;
        }
        .h-20px {
            height: 20px;
        }
        .h-40px {
            height: 40px;
        }
        .h-50px {
            height: 50px;
        }
        .h-120px {
            height: 120px;
        }
        .h-180px {
            height: 180px;
        }
        .w-40px {
            width: 40px;
        }
        .w-50px {
            width: 50px;
        }
        .w-200px {
            width: 200px;
        }
        .w-280px {
            width: 280px;
        }
    }
}
