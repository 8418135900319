@tailwind utilities;
@layer utilities {
  @variants responsive {
    .progress-bar {
      counter-reset: step;
      display: flex;
    }

    .progress-bar-tablet {
      flex-direction: column;
    }

    .progress-bar-step {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: center;
      position: relative;
      @apply text-md;
    }

    .progress-bar-step-tablet {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 16px 0;
      align-items: center;
    }

    .progress-bar-step::before {
      width: theme('spacing.lg');
      height: theme('spacing.lg');
      content: counter(step);
      counter-increment: step;
      align-self: center;
      border-radius: theme('borderRadius.full');
      line-height: calc(theme('spacing.lg') - 4px);
      text-align: center;
      @apply font-header;
      @apply text-base;
      @apply border;
      @apply border-neutral-600;
      @apply text-neutral-600;
    }

    .progress-bar-step-tablet::before {
      margin-left: theme('spacing.lg');
    }

    .progress-bar-step-active {
      @apply bg-neutral-100;
    }

    .progress-bar-step-active::before {
      @apply border-primary-300;
      @apply text-primary-500;
    }

    .progress-bar-step-completed::before {
      @apply bg-primary-600;
      @apply border-primary-600;
      line-height: calc(theme('spacing.lg'));
      content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4415 0.937019C17.8059 1.33346 17.7799 1.95025 17.3835 2.31466L6.33008 13.0459C5.94369 13.4011 5.34549 13.3867 4.97665 13.0133L0.457774 7.77709C0.0793404 7.39402 0.0831022 6.7767 0.466177 6.39826C0.849251 6.01983 1.46658 6.02359 1.84501 6.40666L5.7029 10.9738L16.0638 0.879007C16.4603 0.514602 17.0771 0.540575 17.4415 0.937019Z' fill='%23ECF1FF'/%3E%3C/svg%3E%0A");
    }

    .progress-bar-step-error::before {
      @apply bg-danger-600;
      @apply border-danger-600;
    }

    .progress-bar-step::after {
      height: theme('borderWidth.DEFAULT');
      width: calc(
        100% - theme('spacing.lg') -
          (theme('spacing.progress-bar-line-gap') * 2)
      );
      content: '';
      position: absolute;
      top: calc(theme('spacing.lg') / 2);
      left: calc(
        50% + (theme('spacing.lg') / 2) + theme('spacing.progress-bar-line-gap')
      );
      z-index: 1;
      @apply bg-neutral-600;
    }

    .progress-bar-step-tablet::after {
      margin-left: theme('spacing.lg');
      height: calc(
        100% - theme('spacing.lg') -
          (theme('spacing.progress-bar-line-gap') * 2)
      );
      width: theme('borderWidth.DEFAULT');
      top: calc(
        50% + (theme('spacing.lg') / 2) + theme('spacing.progress-bar-line-gap')
      );
      left: calc(theme('spacing.lg') / 2);
    }

    .progress-bar-step-active::after {
      @apply bg-primary-300;
    }

    .progress-bar-step-completed::after {
      @apply bg-primary-600;
    }

    .progress-bar-step-error::after {
      @apply bg-danger-600;
    }

    .progress-bar-step:last-child::after {
      content: none;
    }
  }
}
